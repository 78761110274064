import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Conditions" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          <p>Last updated: December 30, 2023</p>
            <p>
              Please read these terms and conditions carefully before using Our
              Service.
            </p>
            <h1>License Agreement</h1>
            <p>
              <em>MyChatBar - Free-to-Use License Agreement</em>
            </p>
            <p>
              This License Agreement (the "Agreement") is entered into between
              Delwin Best, a mobile app developer ("Licensor"), and the user
              ("Licensee") for the use of the MyChatBar mobile application (the
              "App").
            </p>
            <h2>Grant of License:</h2>
            <p>
              Licensor grants Licensee a non-exclusive, non-transferable license
              to use the App for personal, non-commercial purposes. This license
              is revocable at any time, and any unauthorized use shall terminate
              it automatically.
            </p>
            <h2>Restrictions:</h2>
            <p>
              Licensee shall not modify, distribute, sell, lease, or reverse
              engineer the App. Any attempt to sublicense, assign, or transfer
              the license is void.
            </p>
            <h2>Intellectual Property:</h2>
            <p>
              The App, including all intellectual property rights, remains the
              property of Licensor. Licensee acknowledges and agrees not to
              infringe upon or dilute these rights.
            </p>
            <h2>Disclaimer of Warranty:</h2>
            <p>
              The App is provided "as is" without warranties of any kind, either
              express or implied, including, but not limited to, the implied
              warranties of merchantability, fitness for a particular purpose,
              or non-infringement.
            </p>
            <h2>Limitation of Liability:</h2>
            <p>
              Licensor shall not be liable for any direct, indirect, incidental,
              special, or consequential damages arising out of the use or
              inability to use the App, even if advised of the possibility of
              such damages.
            </p>
            <h2>Termination:</h2>
            <p>
              This Agreement is effective until terminated by either party.
              Licensor may terminate the license at any time without notice if
              Licensee fails to comply with any terms. Upon termination,
              Licensee must cease using the App.
            </p>
            <p>
              By installing and using the App, Licensee agrees to be bound by
              the terms of this Agreement. If Licensee does not agree to these
              terms, Licensee must uninstall the App.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
