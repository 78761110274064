import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <h1>MyChatBar - Privacy Policy</h1>
            <p>
              <i>Effective Date: December 30, 2023</i>
            </p>

            <p>
              At MyChatBar, we prioritize your privacy and are committed to
              ensuring a secure and transparent user experience. This Privacy
              Policy outlines how we handle user information within the
              MyChatBar mobile app.
            </p>

            <h2>1. No Personal Information Collected:</h2>
            <p>
              We want to assure our users that MyChatBar does not collect,
              store, or use any personal information. We do not require personal
              details such as names, addresses, or contact information during
              the sign-up process.
            </p>

            <h2>2. Communication Privacy:</h2>
            <p>
              All user conversations within MyChatBar, whether text, voice, or
              video, are end-to-end encrypted. This means that your interactions
              with other users are private, secure, and inaccessible to anyone
              other than the intended recipients.
            </p>

            <h2>3. Anonymous User Profiles:</h2>
            <p>
              MyChatBar operates on an anonymous user profile system. Your
              participation in the language-learning community is not associated
              with any identifiable personal information, providing you with a
              secure and confidential language-learning experience.
            </p>

            <h2>4. No Tracking or Analytics:</h2>
            <p>
              We do not use tracking mechanisms or analytics tools that collect
              personal data. MyChatBar is designed to respect your privacy, and
              we do not engage in monitoring your behavior or preferences within
              the app.
            </p>

            <h2>5. Commitment to Free Access:</h2>
            <p>
              Our commitment to free access means that you can enjoy the full
              range of MyChatBar features without the need to provide any
              personal or financial information. Language learning should be
              accessible to everyone, and we uphold this principle.
            </p>

            <h2>6. Third-Party Services:</h2>
            <p>
              MyChatBar does not integrate with third-party services that might
              compromise your privacy. We strive to keep your language-learning
              journey within the secure confines of our app.
            </p>

            <h2>7. Updates to Privacy Policy:</h2>
            <p>
              As MyChatBar evolves, we may make updates to this Privacy Policy.
              Any changes will be communicated within the app, and your
              continued use of MyChatBar indicates your acceptance of these
              updates.
            </p>

            <p>
              By using MyChatBar, you agree to the terms outlined in this
              Privacy Policy. If you have any concerns or questions regarding
              your privacy, please contact us at feedback@mychatbar.com.
            </p>

            <p>
              Thank you for choosing MyChatBar for your language-learning
              adventure!
            </p>

            <p>MyChatBar</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
